import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { getUserCoins } from '../../ApiCalls/Rewards/api_v1_rewards';

const CoinContext = createContext();

export const CoinProvider = ({ children }) => {
  const [coinBalance, setCoinBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const updateCoinBalance = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getUserCoins();
      setCoinBalance(response.data.data.coins);
    } catch (error) {
      console.error('Failed to fetch coin balance:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    updateCoinBalance();
  }, [updateCoinBalance]);

  return (
    <CoinContext.Provider value={{ coinBalance, updateCoinBalance, isLoading }}>
      {children}
    </CoinContext.Provider>
  );
};

export const useCoinBalance = () => useContext(CoinContext);