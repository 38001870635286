import React, { useMemo, useEffect, useState } from 'react';
import { useLocation, Outlet } from "react-router-dom";
import { storeToken, removeTokens, getDecryptMsisdn, getJtToken } from "../../ApiCalls/Auth/api_v1_auth";
import Loader from '../Animations/Loader';

const useAuth = () => {
    const [loggedIn, setLoggedIn] = useState(null);
    const location = useLocation();

    const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const accessCode = searchParams.get("a");

    useEffect(() => {
        const authenticate = async () => {
            try {
                // If access code exists, attempt to log in
                if (accessCode) {
                    const response = await getJtToken({ msisdn: accessCode });
                    if (response.status === 200 && response.headers?.['access_token']) {
                        removeTokens();
                        storeToken(response.headers['access_token']);
                        setLoggedIn(true);
                        return;
                    } else {
                        removeTokens();
                    }
                }

                // If token already exists, check its validity
                const storedToken = localStorage.getItem("token");
                if (storedToken) {
                    const response = await getDecryptMsisdn();
                    if (response.data.data.auth) {
                        setLoggedIn(true);
                    } else {
                        removeTokens();
                        window.location.href = process.env.REACT_APP_LOGIN_URL;
                    }
                } else {
                    setLoggedIn(false);
                }
            } catch (error) {
                console.error('Error verifying token', error);
                removeTokens();
                window.location.href = process.env.REACT_APP_LOGIN_URL;
            }
        };

        authenticate();
    }, [location.pathname]);

    return loggedIn;
};

const ProtectedRoutes = () => {
    const location = useLocation();
    const isAuth = useAuth();

    if (location.pathname === "/share/watch") {
        return <Outlet />;
    }

    if (isAuth === null) { // waiting for authentication check
        return <Loader />;
    }

    return isAuth ? <Outlet /> : (window.location.href = process.env.REACT_APP_LOGIN_URL);
};

export default React.memo(ProtectedRoutes);
