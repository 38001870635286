import React from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useEffect } from 'react';


const ProfileSettings = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const menuStyle = {
    backgroundColor: '#1A1A1A',
    color: 'white',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    minHeight: '100vh',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  };

  const sectionStyle = {
    marginBottom: '40px',
    padding: '16px',
    borderRadius: '16px',
    backgroundColor: "#252525"
  };

  const sectionTitleStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '20px',
  };

  const menuItemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 0',
    cursor: 'pointer',
  };

  const chevronStyle = {
    fontSize: '24px',
    color: '#FFE016',
  };

  function handleLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('ctx');
    localStorage.removeItem('dx');
    localStorage.removeItem('time');
    cookies.remove('uid');
    cookies.remove('rmsid');
    const nextYear = new Date();
    const currentD = new Date();
    nextYear.setFullYear(currentD.getFullYear() + 1);
    cookies.set('uid_exp', 1, { path: '/', expires: nextYear });
    const redirectURL = process.env.REACT_APP_LOGIN_URL;
    window.location.href = redirectURL;
    return;
  }

  function handleUnsub() {
    navigate("/unsubscribe");
  }

  useEffect(() => {
    document.title = "Settings | Ding";
  }, [])


  return (
    <div style={menuStyle}>
      <div style={sectionTitleStyle}>SUPPORT</div>
      <div style={sectionStyle}>
        {/* <div style={menuItemStyle} onClick={() => navigate('/avatar-changes')}>
          <span>Early Avatar Changes</span>
          <span style={chevronStyle}>›</span>
        </div> */}
        <div style={menuItemStyle} onClick={() => navigate('/faqs')}>
          <span>FAQs</span>
          <span style={chevronStyle}>›</span>
        </div>
      </div>
      <div style={sectionTitleStyle}>MORE INFORMATION</div>

      <div style={sectionStyle} onClick={() => navigate('/terms-service')}>
        <div style={menuItemStyle}>
          <span>Terms of Service</span>
          <span style={chevronStyle}>›</span>
        </div>
      </div>

      <div style={sectionTitleStyle}>ACCOUNT ACTIONS</div>
      <div style={sectionStyle}>
        <div style={menuItemStyle} onClick={handleUnsub}>
          <span>Unsubscribe</span>
          <span style={chevronStyle}>›</span>
        </div>
        <div style={menuItemStyle} onClick={handleLogout}>
          <span>Logout</span>
          <span style={chevronStyle}>›</span>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;